import React from "react";
import { css } from "@emotion/react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function Blog({ data }) {
	return (
		<>
			<SEO />
			<Layout>
				{data.allMarkdownRemark.edges.map(({ node }) => (
					<div key={node.id}>
						<Link
							to={node.fields.slug}
							css={css`
								color: black;
							`}
						>
							{node.frontmatter.title}
						</Link>
						<p>{node.frontmatter.date}</p>
					</div>
				))}
			</Layout>
		</>
	);
}
export const query = graphql`
	query {
		allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
			totalCount
			edges {
				node {
					id
					frontmatter {
						title
						date(formatString: "DD MMMM, YYYY")
					}
					fields {
						slug
					}
					excerpt
				}
			}
		}
	}
`;
